<template>
    <defaultSec :title="'活码管理'" :isDisableScroll="true" :isFlexSection="true">
        <section class="route-list">
            <el-scrollbar class="route-left">
                <el-tabs v-model="activeTab">
                    <el-tab-pane label="活码组" name="2">
                        <div style="display: flex;align-items: center">
                            <el-input size="mini" v-model="filterGroupName" placeholder="筛选目录"><i slot="prefix" class="el-input__icon el-icon-search"></i></el-input>
                            <el-button size="small" @click="addEditGroupItem(0)" style="color:#e85656;margin-left:10px;font-size: 18px" icon="el-icon-circle-plus-outline" type="text"></el-button>
                        </div>
                        <div class="custom-tree-node" style="padding: 0 2px 5px" :class="formParam.qrcodeGroupId==''?'is-Current-node':''">
                            <div @click="tripGroupSel({},{id:''})">
                                <i class="el-icon-folder-opened" style="margin-right: 5px"></i>
                                <p class="tree-name">默认</p>
                            </div>
                        </div>
                        <el-tree
                            ref="tree"
                            :data="treeData"
                            :filter-node-method="filterGroupNode">
                            <div class="custom-tree-node" :class="formParam.qrcodeGroupId==data.qrcodeGroupId?'is-Current-node':''" slot-scope="{ node, data }">
                                <div @click="tripGroupSel(node,data)">
                                    <i class="el-icon-folder-opened" :class="data.type==1?'icon-green':'icon-red'" style="margin-right: 5px"></i>
                                    <p class="tree-name">{{ data.qrcodeGroupName }}</p>
                                </div>
                                <el-dropdown placement="bottom-start" class="drop-down" @command="(command)=>handleGroupCommand(command,data)" trigger="click">
                                    <span class="el-dropdown-link">
                                      <i class="icon-edit el-icon-more"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item command="edit">修改目录</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </el-tree>
                    </el-tab-pane>
                </el-tabs>
            </el-scrollbar>
            <el-scrollbar class="route-right">
                <div class="search-content" style="display: flex;justify-content: space-between;align-items: center">
                    <el-form :model="formParam" :inline="true" size="mini" class="demo-form-inline">
                        <el-form-item label="活码名称">
                            <el-input type="text" v-model="formParam.qrName" placeholder="请输入旅程名称"></el-input>
                        </el-form-item>
                        <el-form-item label="选择员工">
                            <el-select clearable v-model="formParam.qyWeiXinUserId" placeholder="请选择">
                                <el-option v-for="item in userList" :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="onSubmit">查询</el-button>
                            <el-button type="danger" @click="handleClick({qrId:0})">新增</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <tablePagination :total="0" :pageSize="formParam.pageSize" @handlePage="handlePage">
                    <el-table
                        v-loading="loading"
                        size="mini"
                        :data="groupList"
                        border
                        style="width: 100%;margin-top: 20px;text-align: center">
                        <el-table-column type="index" label="序号"></el-table-column>
                        <el-table-column label="二维码" width="126">
                            <template slot-scope="scope">
                                <el-image
                                    style="width: 100px; height: 100px"
                                    :src="scope.row.qrPath"
                                    :preview-src-list="[scope.row.qrPath]">
                                    <div slot="error" class="image-slot">
                                        <i class="el-icon-picture-outline"></i>
                                    </div>
                                </el-image>
                            </template>
                        </el-table-column>
                        <el-table-column label="活码名称" prop="qrName"></el-table-column>
                        <el-table-column label="活码分组" prop="groupName"></el-table-column>
                        <el-table-column label="使用员工">
                            <template slot-scope="scope">
                                <span v-for="item in scope.row.userList">{{item.qyWeiXinUserName}} </span>
                            </template>
                        </el-table-column>
                        <el-table-column label="标签" width="200">
                            <template slot-scope="scope">
                                <el-popover
                                    :disabled="(scope.row.tagVoList||[]).length<3"
                                    placement="bottom"
                                    trigger="hover">
                                    <el-tag v-if="index>1" style="margin: 0 5px 5px 0" type="success" size="mini" v-for="(item,index) in scope.row.tagVoList||[]">{{item.tagName}} </el-tag>

                                    <div slot="reference">
                                        <el-tag style="margin: 0 5px 5px 0" v-if="index<2" type="success" size="mini" v-for="(item,index) in scope.row.tagVoList||[]">{{item.tagName}}</el-tag>
                                        <el-tag v-if="(scope.row.tagVoList||[]).length>2" style="margin: 0 5px 5px 0" type="success" size="mini">...</el-tag>
                                    </div>
                                </el-popover>
                            </template>
                        </el-table-column>
                        <el-table-column label="更新时间" width="140">
                            <template slot-scope="scope">
                                {{scope.row.updateTime||scope.row.createTime}}
                            </template>
                        </el-table-column>
                        <el-table-column
                            fixed="right"
                            label="操作"
                            width="120">
                            <template slot-scope="scope">
                                <el-button @click="handleClick(scope.row)" type="text" size="small">编辑|查看</el-button>
                                <el-dropdown style="margin-left: 16px" placement="bottom-start" @command="handleGroupItem">
                                    <el-button type="text" class="el-dropdown-link">
                                        •••
                                    </el-button>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item :command="{state:'download',item:scope.row}">下载</el-dropdown-item>
                                        <el-dropdown-item :command="{state:'delRoute',item:scope.row}">删除</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>

                            </template>
                        </el-table-column>
                    </el-table>
                </tablePagination>
            </el-scrollbar>
            <el-dialog
                title="新建/编辑目录"
                :visible.sync="dialogVisible"
                width="60%">
                <el-form label-width="80px">
                    <el-form-item label="目录名称">
                        <el-input clearable size="small" placeholder="请输入名称" v-model="selGroup.qrcodeGroupName"></el-input>
                    </el-form-item>
                </el-form>

                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitGroupItem">确 定</el-button>
                </span>
            </el-dialog>
        </section>
    </defaultSec>
</template>

<script>
import defaultSec from '@c/defaultSection'
import tablePagination from '@c/tablePagination'
let apiDone = null
export default {
    name: "groupManage",
    data(){
        return {
            loading:false,
            activeTab:'2',
            treeData:[],
            userList:[],
            total: 0,
            formParam: {
                pageSize: 20,
                currentPage: 1,
                qrName: '',
                qyWeiXinUserId: '',
                 qrcodeGroupId: '',
            },
            //目录参数
            sceneRoute:2,//1,群组；2：旅程
            groupList: [],
            dialogVisible:false,
            filterGroupName:'',
            selGroup:{},
            selRoute:{},
            dialogAddVisible:false,
            destroyState:false
        }
    },
    components: {defaultSec,tablePagination},
    mounted() {
        this.initUserList()
        this.initList()
        this.initGroupList()
    },
    watch: {
        filterGroupName(val) {
            console.log(val)
            this.$refs.tree.filter(val);
        }
    },
    //页面销毁之前终端数据循环
    beforeDestroy() {
        clearTimeout(apiDone)
        this.destroyState = true
    },
    methods: {
        //企微客服列表
        initUserList(){
            this.$apiGet('lechun-cms/scrmCalendar/getQwUser', {}).then(res => {
                this.userList = res
            })
        },
        initList() {
            this.formParam.currentPage = 1
            this.groupList=[]
            this.loading = true
            console.log('time',apiDone)
            clearTimeout(apiDone)
            this.asyncInitTableData()
        },
        asyncInitTableData(){
            this.$apiGet('lechun-cms/scrmQrcode/getQrcodeList', this.formParam).then(res => {
                console.log(this.groupList)
                this.groupList = [...this.groupList,...res.list]
                this.total = res.total
                this.loading = false
                console.log(this.groupList.length,this.total)
                if(!this.destroyState&&this.groupList.length<this.total){
                  console.log('w')
                    apiDone = setTimeout(()=>{
                        this.formParam.currentPage++
                        this.asyncInitTableData()
                    },100)
                }
            })
        },
        //初始化目录列表
        initGroupList(){
            this.$apiGet('lechun-cms/scrmQrcode/getQrcodeGroupList', {currentPage: 1,pageSize: 10000}).then(res => {
                this.treeData = res
            })
        },
        //活码目录选中
        tripGroupSel(node,data){
            this.formParam.qrcodeGroupId = data.qrcodeGroupId
            this.initList()
        },
        //活码目录筛选
        filterGroupNode(value,data){
            if(!value) return true;
            return data.qrcodeGroupName.indexOf(value)!==-1
        },
        //活码目录操作
        handleGroupCommand(command,data){
            //目录编辑
            if(command=='edit'){
                this.addEditGroupItem(data)
            }
        },
        //添加编辑目录
        addEditGroupItem(item){
            this.dialogVisible = true
            if(item){
                this.selGroup = {
                    qrcodeGroupId:item.qrcodeGroupId,
                    qrcodeGroupName:item.qrcodeGroupName,
                }
            }else{
                this.selGroup = {
                    qrcodeGroupId:'',
                    qrcodeGroupName:'',
                }
            }
        },
        //保存目录名称
        submitGroupItem(){
            this.$apiGet('lechun-cms/scrmQrcode/saveQrcodeGroup',{ ...this.selGroup}).then(res => {
                this.$message({
                    type: 'success',
                    message: '保存成功'
                });
                this.dialogVisible = false
                this.initGroupList()
            })
        },
        //跳页执行
        handlePage(page){
            this.formParam.currentPage = page;
            this.initList()
        },
        //更多操作
        handleGroupItem(dataItem){
            console.log(dataItem)
            let dataInfo = dataItem.item

            //删除活码
            if(dataItem.state=='delRoute'){
                this.$confirm('确定删除选择的用户活码吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$apiGet('lechun-cms/scrmQrcode/deleteQrcode',{ qrcodeId: dataInfo.qrId,configId:dataInfo.configId}).then(res => {
                        this.$message({
                            showClose: true,
                            message: '成功',
                            type: 'success'
                        });
                        this.initList();
                    });
                }).catch(() => {

                });
            }
            //下载活码
            if(dataItem.state=='download'){
                this.$downloadImage(dataInfo.qrPath,dataInfo.qrName)
            }
        },
        onSubmit(){
            this.initList()
        },
        handleClick(row){
            this.$router.push({path: '/qrcodeDetail/:id', name: 'qrcodeDetail', params: {id: row.qrId}})
        },
    }
}
</script>

<style  lang="scss" rel="stylesheet/scss">

.route-list{
    display: flex;
    height: 100%;
    .popper-content{
        box-shadow: 0 0 10px 0 rgba(0,0,0,.4)
    }
    .route-left{
        width: 140px;
        border-radius: 5px 0 0 5px;
        height: calc(100% - 20px);
        padding: 10px;
        background: #f5f5f5;
        font-size: 12px;
        box-shadow: 1px 0 6px 0 rgba(0,0,0,.26);
        position: relative;
        z-index: 9;
    }
    .route-left-item{
        cursor: pointer;
    }
    .route-left-item-on{
        background: rgba(32,158,145,.2);
    }
    .route-right{
        flex: 1;
        height: calc(100% - 20px);
        background: #ffffff;
        border-radius: 0 5px 5px 0;
        padding: 10px 10px 10px 15px;
        p{
            margin: 0;
        }
    }
    .copy-text{
        white-space: pre-line;
        margin: 0;
        line-height: 18px;
        background: #f2f2f2;
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        padding: 6px;
        max-height: 127px;
        overflow-y:scroll;
      scrollbar-width: none;
    }
    .el-tabs__item{
        font-size: 12px;
        padding: 0 9px;
    }
    .el-tree{
        background: transparent;
        font-size: 12px;
    }
    .custom-tree-node {
        flex: 1;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: #606060;
        .tree-name{
            position: relative;
            top: 4px;
            display: inline-block;
            width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 0;
            cursor: pointer;
        }
        .drop-down{
            font-size: 12px;
            color: #2dacd1;
            line-height: 24px;
        }
        .icon-edit{
            transform: rotate(90deg);
        }
        .icon-green{
            color: #55a532;
        }
        .icon-red{
            color: #e50011;
        }
    }
    .el-tree-node__content > .el-tree-node__expand-icon{
        padding: 1px;
        font-size: 0;
    }
    .is-Current-node{
        background: rgba(32, 158, 145, 0.2);
    }
    .el-dialog{
        .el-tree{
            font-size: 14px;
        }
        .tree-name{
            width: 100%;
            font-size: 14px;
        }
    }
    .el-dialog__body{
        padding-top: 20px;
    }
}

</style>
